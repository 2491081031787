import configureGraphQlClient from '@dehaat/kisan-app-bl/graphql'
import { captureException } from '@sentry/nextjs'
import Axios, { AxiosError, AxiosRequestHeaders } from 'axios'

export const axiosInstance = (token?: string, latLong?: string) => {
  // GraphQl client for server-side config
  configureGraphQlClient({
    endPoint: `${process.env.NEXT_PUBLIC_API_BASE_URL}/graphql/`,
    headers: token
      ? {
          'X-AUTH-SCHEME': 'keycloak',
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  })
  const config = {
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    headers: token
      ? {
          'X-AUTH-SCHEME': 'keycloak',
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  }

  const axios = Axios.create(config)
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.common['APP-CODE'] = 'dehaat_farmer_web'
  if (latLong) {
    axios.defaults.headers.common['X-LATLONG'] = latLong
  }

  axios.interceptors.response.use(
    (response) => {
      if (response.data.error == null) {
        return response
      }
      return Promise.reject(response.data.error)
    },
    (error: AxiosError) => {
      if (
        !error.response ||
        (error.response.status < 400 && error.response.status >= 500)
      ) {
        captureException(error)
      }
      return Promise.reject(error)
    }
  )

  return axios
}

export async function get<T>(
  url: string,
  params: Record<string, unknown> = {},
  token?: string,
  headers?: AxiosRequestHeaders,
  signal?: AbortSignal
) {
  return (await axiosInstance(token).get<T>(url, { params, headers, signal }))
    .data
}
