import { useRouter } from 'next/router';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { INSURANCE_KEY } from '@/constants/common';
import { InsuranceItem, LSInsuranceData } from '@/models/ProductInsurance';
import { getLocalStorageKey, setLocalStorageKey } from '@/utils/helper';
import { CartContext } from './CartProvider';
interface ViewPolicyDetails {
  insuranceId: number;
  variantId: number;
}
interface Context {
  farmerId: number | null;
  insuranceItems: Record<string, InsuranceItem>;
  resetInsuranceItems: VoidFunction;
  setViewPolicyDetails: Dispatch<SetStateAction<ViewPolicyDetails | null>>;
  updateFarmerId: (farmerId: number | null) => void;
  updateInsuranceItems: (variantId: string, insuranceId: number, qty: number, freeQty: number, type: string) => void;
  viewPolicyDetails: ViewPolicyDetails | null;
}
export const InsuranceContext = createContext<Context>({
  farmerId: null,
  insuranceItems: {},
  resetInsuranceItems: () => undefined,
  setViewPolicyDetails: () => undefined,
  updateFarmerId: () => undefined,
  updateInsuranceItems: () => undefined,
  viewPolicyDetails: null
});
interface Props {
  children: ReactNode;
}
const InsuranceProvider = ({
  children
}: Props) => {
  const {
    cartItems,
    isPickupOrder
  } = useContext(CartContext);
  const [farmerId, setFarmerId] = useState<number | null>(null);

  // Maps from variantId to insurance purchased quantity and type
  const [insuranceItems, setInsuranceItems] = useState<Record<string, InsuranceItem>>({});
  const [viewPolicyDetails, setViewPolicyDetails] = useState<ViewPolicyDetails | null>(null);
  const {
    asPath
  } = useRouter();
  const updateInsuranceItems = useCallback((variantId: string, insuranceId: number, qty: number, freeQty: number, type: string) => {
    // Update the state
    setInsuranceItems(prev => {
      const copy = {
        ...prev
      };
      if (qty > 0) {
        return {
          ...copy,
          [variantId]: {
            id: insuranceId,
            qty,
            freeQty,
            type
          }
        };
      }
      delete copy[variantId];
      return copy;
    });

    // Update local storage
    const existingData = getLocalStorageKey<LSInsuranceData, null>(INSURANCE_KEY) || {
      items: ({} as Record<string, InsuranceItem>)
    };
    if (qty > 0) {
      existingData.items = {
        ...existingData.items,
        [variantId]: {
          id: insuranceId,
          qty,
          freeQty,
          type
        }
      };
    } else {
      delete existingData.items[variantId];
    }
    setLocalStorageKey<LSInsuranceData>(INSURANCE_KEY, existingData);
  }, []);
  const resetInsuranceItems = useCallback(() => {
    setInsuranceItems({});
    const newInsuranceLs: LSInsuranceData = {
      items: {}
    };
    if (farmerId) {
      newInsuranceLs.farmer_id = farmerId;
    }
    setLocalStorageKey<LSInsuranceData>(INSURANCE_KEY, newInsuranceLs);
  }, [farmerId]);
  const updateFarmerId = useCallback((farmerId: number | null) => {
    setFarmerId(farmerId);
    const lsInsuranceData = getLocalStorageKey<LSInsuranceData, null>(INSURANCE_KEY, null);
    const newLsData: LSInsuranceData = {
      items: lsInsuranceData?.items || {}
    };
    if (farmerId) {
      newLsData.farmer_id = farmerId;
    }
    setLocalStorageKey<LSInsuranceData>(INSURANCE_KEY, newLsData);
  }, []);

  // Make sure the context is up to date with the local storage
  // every time the user navigates to some new page
  useEffect(() => {
    if (isPickupOrder) {
      resetInsuranceItems();
      return;
    }
    const lsInsuranceData = getLocalStorageKey<LSInsuranceData, null>(INSURANCE_KEY, null);
    if (lsInsuranceData) {
      setInsuranceItems(lsInsuranceData.items);
      setFarmerId(lsInsuranceData.farmer_id || null);
    }
  }, [asPath, isPickupOrder, resetInsuranceItems]);

  // Match the insuranceItems quantities with cartItems quantities
  // whenever a product variant's quantity in the cart
  // goes below the quantity of its corresponding insurance items added
  // or remove it if the variant has been removed from the cart
  useEffect(() => {
    // Remove insurance items that do not have variants in the cart
    for (const variantId in insuranceItems) {
      if (!cartItems[variantId]) {
        updateInsuranceItems(variantId, 0, 0, 0, '');
      }
    }

    // Match the quantity for variants in the cart
    for (const variantId in cartItems) {
      if (insuranceItems[variantId] && cartItems[variantId] < insuranceItems[variantId].qty) {
        updateInsuranceItems(variantId, insuranceItems[variantId].id, cartItems[variantId], Math.min(cartItems[variantId], insuranceItems[variantId].freeQty), insuranceItems[variantId].type);
      }
    }
  }, [cartItems, insuranceItems, updateInsuranceItems]);
  return <InsuranceContext.Provider value={{
    farmerId,
    insuranceItems,
    resetInsuranceItems,
    setViewPolicyDetails,
    updateFarmerId,
    updateInsuranceItems,
    viewPolicyDetails
  }} data-sentry-element="unknown" data-sentry-component="InsuranceProvider" data-sentry-source-file="InsuranceProvider.tsx">
      {children}
    </InsuranceContext.Provider>;
};
export default InsuranceProvider;