import useTranslation from 'next-translate/useTranslation';
import { PropsWithChildren, useEffect } from 'react';
import styles from '@/styles/ErrorModal.module.css';
import CrossIcon from './icons/Cross';
import ErrorIcon from './icons/Error';
interface Props {
  show: boolean;
  message: string;
  messageDetail?: string;
  onClose: VoidFunction;
  allowClose?: boolean;
  showIcon?: boolean;
}
const ErrorModal = ({
  show,
  children,
  message,
  messageDetail,
  onClose,
  allowClose = true,
  showIcon = true
}: PropsWithChildren<Props>) => {
  const {
    t
  } = useTranslation('common');
  useEffect(() => {
    if (show) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [show]);
  return <>
      {show ? <section data-testid="error-modal">
          <div className="fixed inset-0 bg-neutral-90 z-50 opacity-70" />
          <div className={`${styles.animate} fixed top-1/2 left-4 right-4 -translate-y-1/2 bg-white p-6 flex flex-col z-50 justify-between items-center gap-4 md:left-1/3 md:right-1/3 rounded-2xl`}>
            {allowClose && <CrossIcon className="absolute top-3 right-5 fill-transparent h-5 stroke-neutral-80 w-5" onClick={onClose} data-testid="error-modal-cross" />}
            {showIcon && <ErrorIcon className="w-14 fill-error-100" />}
            <div className="text-neutral-90 text-center">
              <h5>{message?.length > 0 ? message : t('default_error_msg')}</h5>
              {messageDetail && <p className='mt-4'>{messageDetail}</p>}
            </div>
            {children}
          </div>
        </section> : null}
    </>;
};
export default ErrorModal;