import { CartCaptureEvent, PurchaseEvent } from './types'

export const capturePurchaseEvent = ({
  value,
  currency = 'INR',
  products,
}: PurchaseEvent) => {
  if (window.fbq != null) {
    const contents = products.map(({ variantId, productId, quantity = 1 }) => ({
      id: variantId || productId,
      quantity,
    }))
    const quantity = products.reduce(
      (prev, { quantity = 1 }) => prev + quantity,
      0
    )
    fbq('track', 'Purchase', {
      currency,
      value,
      content_type: 'product',
      contents,
      num_items: quantity,
    })
  }
}

export const captureCartEvent = ({
  name,
  variantPrice,
  variantDiscount,
  variantId,
  brandName,
  category,
}: CartCaptureEvent) => {
  if (window.fbq != null) {
    const contentCategory = [category?.name, brandName]
      .filter(Boolean)
      .join(' > ')
    const contentIds = [variantId]
    const value = variantPrice - variantDiscount

    fbq('track', 'AddToCart', {
      content_name: name,
      content_category: contentCategory,
      content_ids: contentIds,
      content_type: 'product',
      value,
      currency: 'INR',
    })
  }
}
