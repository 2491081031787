import { isProductionBuild } from './helper'


const isClient = typeof window !== 'undefined';

// Headless chrome and Crawler bots are by default blocked by Moengage
// This checks for these agents
export const isMoengageBlockedUserAgent = () =>
  isClient && /bot|headless|crawler|spider|crawling/i.test(navigator?.userAgent)

export const shouldInitializeMoengage = () =>
  isProductionBuild && !isMoengageBlockedUserAgent()

export const isMoengageIntialized = () => 'Moengage' in window
