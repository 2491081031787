export enum CART_TYPE {
  ONDC = 'ondc',
  DEHAAT = 'dehaat',
}

export const ONDC_ERROR_CODES = {
  PROVIDER_ERROR: new RegExp(/^provider_error/),
  CART_ERROR: new RegExp(/^cart_error/),
  LOCATION_ERROR: new RegExp(/^location_error/),
}
