import mixpanel from 'mixpanel-browser'

interface MixpanelProperties {
  [key: string]: any
}
interface MixpanelProps {
  track: (name: string, props?: MixpanelProperties) => void
  identify: (id: string) => void
  setUser: (props: MixpanelProperties) => void
  reset: () => void
}

export const initMixpanel = () => {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
  })
}

const Mixpanel: MixpanelProps = {
  track: (name, props) => mixpanel.track(name, props),
  identify: (id) => mixpanel.identify(id),
  setUser: (props) => mixpanel.people.set(props),
  reset: () => mixpanel.reset(),
}

export default Mixpanel
