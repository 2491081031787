import { captureException } from '@sentry/nextjs'
import Axios, { AxiosError } from 'axios'

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_KEY_CLOAK_URL,
})

axios.interceptors.request.use(async (config) => {
  const copy = { ...config }
  if (
    copy.url?.endsWith('realms/dehaat/custom/sendOTP') ||
    copy.url?.endsWith('realms/dehaat/custom/logout')
  ) {
    copy.headers['Content-Type'] = 'application/json'
  } else {
    copy.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  return copy
})

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (
      !error.response ||
      (error.response.status < 400 && error.response.status >= 500)
    ) {
      captureException(error)
    }
  }
)

export default axios
