import User from '@dehaat/kisan-app-bl/models/User'

import Mixpanel from '@/utils/analytics/mixpanel'

import * as GA from './googleAnalytics'
import * as FB from './pixelAnalytics'
import { CartCaptureEvent, PurchaseEvent } from './types'

export const capturePurchaseEvent = (eventData: PurchaseEvent) => {
  GA.capturePurchaseEvent(eventData)
  // FB.capturePurchaseEvent(eventData)
}

export const captureCartEvent = (eventData: CartCaptureEvent) => {
  GA.CaptureUpdateCartEvent(eventData)
  if (eventData.eventType === 'add_to_cart') {
    FB.captureCartEvent(eventData)
  }
}

export const loginEvent = (userInfo: User) => {
  window.gtag('set', { user_id: userInfo.auth_id })
  Mixpanel.identify(userInfo.auth_id || '')
  Mixpanel.setUser({
    username: userInfo.username,
    name: userInfo.full_name,
    email: userInfo.email,
    mobile: userInfo.phone_number,
  })
}
