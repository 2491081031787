import { getLocalStorageKey } from '../helper'

export const multiLocalStorageSync = <T>(
  keys: Array<{ key: string; defaultValue?: T | null }>,
  callback: (key: string, value: T | null | undefined) => void,
) => {
  keys.forEach(({ key, defaultValue }) => {
    const value = getLocalStorageKey<T, T | null | undefined>(
      key,
      defaultValue ?? null,
    )
    callback(key, value)
  })
}
