import { ReactNode, useCallback, useState } from 'react'

interface State {
  show: boolean
  message: string
  actionNode?: ReactNode
  allowClose?: boolean
  additionalInfo?: unknown
  removeServicesInCart?: boolean
}

const defaultState = {
  show: false,
  message: '',
  actionNode: null,
  allowClose: true,
  removeServicesInCart: false,
}

const useErrorModalState = () => {
  const [errorModalState, setErrorModalState] = useState<State>(defaultState)

  const hideErrorModal = useCallback(() => {
    setErrorModalState(defaultState)
  }, [])
  return {
    errorModalState,
    setErrorModalState,
    hideErrorModal,
  }
}

export default useErrorModalState


