import Script from 'next/script';
import { useContext, useEffect } from 'react';
import { UserContext } from '@/context/UserProvider';
import { loginEvent } from '@/utils/analytics';
const GoogleAnalytics = () => {
  const {
    user
  } = useContext(UserContext);
  useEffect(() => {
    let timer: number;
    if (user) {
      timer = window.setInterval(() => {
        if (window.gtag != null) {
          clearInterval(timer);
          loginEvent(user || {});
        }
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [user]);
  return <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_KEY}`} strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
      <Script id="google-analytics" strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GA_KEY}');
          gtag('config', '${process.env.NEXT_PUBLIC_GA_CONVERSION_KEY}');
        `}
      </Script>
    </>;
};
export default GoogleAnalytics;