import CustomEventTypes from '../analytics/customEventTypes';
import { trackCustomEvent } from '../analytics/googleAnalytics';
export const askNotificationPermission = async () => {
  if ('Notification' in window) {
    const permission = await window.Notification.requestPermission();
    if (permission === 'granted') {
      trackCustomEvent(CustomEventTypes.NOTIFICATION_PERMISSION_GRANTED);
    } else {
      trackCustomEvent(CustomEventTypes.NOTIFICATION_PERMISSION_DENIED);
    }
  }
};
export const isNotificationPermissionAvailable = () => {
  if ('Notification' in window) {
    if (Notification.permission === 'default') {
      return false;
    } else {
      return true;
    }
  }
};