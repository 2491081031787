import { CropsWithCategoryProps } from '@dehaat/kisan-app-bl/models/CropV2';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { RootState } from '../index';
interface CropSlice {
  allCrops: CropsWithCategoryProps[];
}
const initialState: CropSlice = {
  allCrops: []
};
const cropSlice = createSlice({
  name: 'crop',
  initialState,
  reducers: {
    addAllCrops: (state, action: PayloadAction<CropsWithCategoryProps[]>) => {
      state.allCrops = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action: any) => {
      state.allCrops = action?.payload?.crop?.allCrops;
    });
  }
});
export const {
  addAllCrops
} = cropSlice.actions;
export const selectCrop = (state: RootState) => state.crop.allCrops;
export default cropSlice;