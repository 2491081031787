import { Category } from '@dehaat/kisan-app-bl/models/Product'

import { Product } from '@/models/Product'

export enum ProductListTypeEnum {
  SEARCH,
  PRODUCT,
  CART,
  FEED,
  CROP_PROBLEM,
  PRODUCT_DETAIL,
}

export type CartItem = {
  productId?: string
  brandId?: string
  brandName?: string
  categoryId?: string
  categoryName?: string
  itemName?: string
  variantId?: string
  index?: number
  price?: number
  discount?: number
  quantity?: number
  vendorName?: string
  vendorLocationId?: string
}

export type CartCaptureEvent = {
  eventType: 'add_to_cart' | 'remove_from_cart'
  type?: ProductListTypeEnum
  listName?: string
  quantity?: number
  index?: number
  category?: Category
  variantId: string
  variantPrice: number
  variantDiscount: number
  name?: string
  brandName?: string
}

export type PurchaseEvent = {
  orderId: number
  value: number
  shipping?: number
  currency?: string
  coupon?: string
  type?: 'purchase' | 'refund'
  products: CartItem[]
}

export type ViewEvent = {
  product: Product
  currency?: string
  type?: ProductListTypeEnum
}
