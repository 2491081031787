import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { isProductionBuild } from '@/utils/helper';
import cropSlice from './features/cropSlice';
import locationSlice from './features/locationSlice';
import serviceSlice from './features/serviceSlice';
import userSlice from './features/userSlice';
const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [cropSlice.name]: cropSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
  [serviceSlice.name]: serviceSlice.reducer
});
export const makeStore = () => configureStore({
  reducer: rootReducer,
  devTools: !isProductionBuild
});
export type AppStore = ReturnType<typeof makeStore>;
export const wrapper = createWrapper<AppStore>(makeStore);
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']>;