import AuthToken, { APP_CODE_TYPE } from '@/models/Auth'

import getClientCode from '../helpers/getClientCode'

const fetchTokenFromRefreshToken = async (
  refreshToken: string,
  appCode?: boolean | APP_CODE_TYPE,
  redirectUri?: string
) => {
  const clientId = getClientCode(appCode) as string
  const formData = new URLSearchParams()
  formData.append('refresh_token', refreshToken)
  formData.append('grant_type', 'refresh_token')
  formData.append('client_id', clientId)
  if (redirectUri) {
    formData.append('redirect_uri', redirectUri)
  }
  try {
    const response = await fetch(
      `${
        process.env.NEXT_PUBLIC_KEY_CLOAK_URL as string
      }realms/dehaat/protocol/openid-connect/token`,
      {
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: formData.toString(),
      }
    )
    const out = await response.json()
    if (response.ok) {
      return out as AuthToken
    }
  } catch {}
  return null
}

export default fetchTokenFromRefreshToken
