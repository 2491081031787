import useTranslation from 'next-translate/useTranslation';
interface Props {
  handleClick: VoidFunction;
  text?: string;
}
const Okay = ({
  handleClick,
  text = 'okay'
}: Props) => {
  const {
    t
  } = useTranslation('common');
  return <button data-testid="error-modal-okay-btn" className="bg-primary-100 text-white px-4 py-2 rounded-lg min-w-28" onClick={handleClick} data-sentry-component="Okay" data-sentry-source-file="Okay.tsx">
      {t(text)}
    </button>;
};
export default Okay;