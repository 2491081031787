import User from '@dehaat/kisan-app-bl/models/User';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
interface UserState {
  user?: User;
}
const initialState: UserState = {};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (_, action) => {
      return action.payload;
    }
  }
});
export const {
  addUser
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice;