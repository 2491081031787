import { APP_CODE_TYPE } from '@/models/Auth'

import { isCSCUser } from '../helper'

const getClientCode = (appCode?: boolean | APP_CODE_TYPE) => {
  if (isCSCUser()) return process.env.NEXT_PUBLIC_KEY_CLOAK_CSC_CLIENT_ID
  if (appCode === true || appCode === '01')
    return process.env.NEXT_PUBLIC_KEY_CLOAK_APP_CLIENT_ID
  if (appCode === '02') return process.env.NEXT_PUBLIC_KEY_CLOAK_AIMS_CLIENT_ID
  if (appCode === '03')
    return process.env.NEXT_PUBLIC_KEY_CLOAK_VR_APP_CLIENT_ID
  return process.env.NEXT_PUBLIC_KEY_CLOAK_CLIENT_ID
}

export default getClientCode
