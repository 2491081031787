import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import ErrorModal from '@/components/ErrorModal';
export interface State {
  show: boolean;
  message: string;
  messageDetail?: string;
  actionNode?: ReactNode;
  allowClose?: boolean;
  additionalInfo?: unknown;
  removeServicesInCart?: boolean;
  showIcon?: boolean;
}
const defaultState = {
  show: false,
  message: '',
  actionNode: null,
  allowClose: true,
  removeServicesInCart: false
};
interface Context {
  hideErrorModal: () => void;
  errorModalState: State;
  setErrorModalState: Dispatch<SetStateAction<State>>;
}
export const ErrorModalContext = createContext<Context>({
  hideErrorModal: () => undefined,
  errorModalState: defaultState,
  setErrorModalState: () => undefined
});
interface Props {
  children: ReactNode;
}
const ErrorModalProvider = ({
  children
}: Props) => {
  const [errorModalState, setErrorModalState] = useState<State>(defaultState);
  const hideErrorModal = useCallback(() => {
    setErrorModalState(defaultState);
  }, []);
  return <ErrorModalContext.Provider value={{
    errorModalState,
    hideErrorModal,
    setErrorModalState
  }} data-sentry-element="unknown" data-sentry-component="ErrorModalProvider" data-sentry-source-file="ErrorModalProvider.tsx">
      {children}
      {/* ErrorModal should be rendered in the end so it appears on top,
       otherwise it hides behind other modals due to similar `fixed z-50` e.g. OTP modal */}
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="ErrorModalProvider.tsx">
        {errorModalState.actionNode}
      </ErrorModal>
    </ErrorModalContext.Provider>;
};
export default ErrorModalProvider;