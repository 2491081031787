import axios from '../axios/loginConfig'

interface Request {
  client_id: string
  refresh_token: string
  client_secret?: string
}

const keycloakLogout = async (refreshToken: string, clientSecret?: string) => {
  const body: Request = {
    client_id: process.env.NEXT_PUBLIC_KEY_CLOAK_CLIENT_ID as string,
    refresh_token: refreshToken,
  }
  if (clientSecret) {
    body.client_secret = clientSecret
  }
  try {
    axios.post('realms/dehaat/custom/logout', body)
    return true
  } catch {
    return false
  }
}

export default keycloakLogout
