import { CropsWithCategoryProps } from '@dehaat/kisan-app-bl/models/CropV2'

import SoilTestImage from '@/images/non-hyperlocal/soil-testing.webp'
import Crop from '@/images/shri-program/crop.webp'
import CropSurvey from '@/images/shri-program/crop-survey.webp'
import Delivery from '@/images/shri-program/delivery.webp'
import Farmer from '@/images/shri-program/farmer.webp'
import FarmerHelp from '@/images/shri-program/farmer-help.webp'
import FarmerMetting from '@/images/shri-program/farmer-meeting.webp'
import GeoTagging from '@/images/shri-program/geo-tagging.webp'
import Soil from '@/images/shri-program/soil.webp'
import VideoCall from '@/images/shri-program/video-call.webp'
import { ServiceItem } from '@/models/shriProgram'
import { isProductionBuild } from '@/utils/helper'

const shriProgramDetails = [
  {
    heading: 'about_shree_program',
    items: [
      { image: Crop, description: 'about_program_1' },
      { image: Farmer, description: 'about_program_2' },
    ],
  },
  {
    heading: 'benefits_of_shree_program',
    items: [
      { image: Soil, description: 'benefits_1' },
      { image: FarmerHelp, description: 'benefits_2' },
      { image: Delivery, description: 'benefits_3' },
      { image: CropSurvey, description: 'benefits_4' },
      { image: FarmerMetting, description: 'benefits_5' },
      { image: VideoCall, description: 'benefits_6' },
      { image: GeoTagging, description: 'benefits_7' },
    ],
  },
]

export const shriProgramCrops: CropsWithCategoryProps = {
  category: 'श्री किसान कार्यक्रम से जुड़े फसल',
  crops: [
    {
      id: 106,
      name: 'Maize',
      app_display_name: 'मक्का',
      image_url:
        'https://s3-ap-south-1.amazonaws.com/aeros-data/advisory/b3b4f096-7585-4753-9abb-2a8d013f2bdd.png',
      aeros_id: 17,
      is_perennial: false,
      is_khetibook_enabled: true,
      tags: [110],
      slug: {
        slug: 'Maize',
        redirect_url: 'https://dehaat.in',
        metadata: {},
      },
    },
    {
      id: 22,
      name: 'Paddy',
      app_display_name: 'धान',
      image_url:
        'https://s3-ap-south-1.amazonaws.com/aeros-data/advisory/fe36c477-dbd7-49c0-98c4-8c74f7907d7a.png',
      aeros_id: 106,
      is_perennial: false,
      is_khetibook_enabled: true,
      tags: [112],
      slug: {
        slug: 'Paddy',
        redirect_url: 'https://dehaat.in',
        metadata: {},
      },
    },
    {
      id: 129,
      name: 'Cotton',
      app_display_name: 'कपास',
      image_url:
        'https://s3-ap-south-1.amazonaws.com/aeros-data/advisory/69e479cc-4bfd-41c8-9678-946095d704a7.png',
      aeros_id: 221,
      is_perennial: false,
      is_khetibook_enabled: true,
      tags: [24],
      slug: {
        slug: 'Cotton',
        redirect_url: 'https://dehaat.in',
        metadata: {},
      },
    },
    {
      id: 32,
      name: 'Sugarcane',
      app_display_name: 'गन्ना',
      image_url:
        'https://s3-ap-south-1.amazonaws.com/aeros-data/advisory/eb411c28-88c9-4ae2-810f-13e8cfd993fc.png',
      aeros_id: 12,
      is_perennial: false,
      is_khetibook_enabled: true,
      tags: [84],
      slug: {
        slug: 'Sugarcane',
        redirect_url: 'https://dehaat.in',
        metadata: {},
      },
    },
  ],
}

export const cropIntials = ['SG', 'MZ', 'CO', 'PD']

export const shricardProductVariantIds = isProductionBuild
  ? ['16731', '16732', '16733', '16758']
  : ['16022', '16023', '16024', '16025']

export const relatedServiesProductVariantIds = isProductionBuild
  ? ['16438', '16558', '16769']
  : ['16018', '16019', '16026']

export const allServicesProductVariantIds = shricardProductVariantIds.concat(
  relatedServiesProductVariantIds
)

export enum SHRI_CROPS {
  SG = 'Sugarcane',
  CO = 'Cotton',
  MZ = 'Maize',
  PD = 'Paddy',
}

export const relatedServices: ServiceItem[] = [
  // {
  //   title: 'मिट्टी जांच',
  //   priceText: '₹ 199/- प्रति acre',
  //   icon: SoilTestImage,
  //   desc: 'अपने अन्य खेतो के लिए भी मिट्टी जांच book करें',
  //   productVariantId: relatedServiesProductVariantIds[0],
  // },
  // {
  //   title: 'ड्रोन स्प्रे सर्विस',
  //   priceText: '₹ 499/-',
  //   icon: DroneImage,
  //   desc: 'कीटनाशकों का छिड़काव करवाएं ड्रोन के द्वारा',
  //   productVariantId: relatedServiesProductVariantIds[1],
  // },
  {
    title: 'Shri Card Soil Test Report',
    priceText: '₹ 150/-',
    icon: SoilTestImage,
    desc: 'Soil test report ke paise sath jama karen',
    productVariantId: relatedServiesProductVariantIds[2],
  },
]

export enum SHRI_CARD_TYPE {
  PRODUCT = 'product',
  SERVICE = 'service',
  // BOTH = 'shri_both_type',
  COUPON = 'coupon',
}

export const ShriTypeProdMapping = {
  [SHRI_CARD_TYPE.PRODUCT]: {
    variantId: '16731',
  },
  [SHRI_CARD_TYPE.SERVICE]: {
    variantId: '16732',
  },
  // [SHRI_CARD_TYPE.BOTH]: {
  //   variantId: '16733',
  // },
  [SHRI_CARD_TYPE.COUPON]: {
    variantId: '16758',
  },
}

export const ShriTypeDevMapping = {
  [SHRI_CARD_TYPE.PRODUCT]: {
    variantId: '16022',
  },
  [SHRI_CARD_TYPE.SERVICE]: {
    variantId: '16023',
  },
  // [SHRI_CARD_TYPE.BOTH]: {
  //   variantId: '16024',
  // },
  [SHRI_CARD_TYPE.COUPON]: {
    variantId: '16025',
  },
}

export const ShriTypeMapping =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? ShriTypeProdMapping
    : ShriTypeDevMapping

export default shriProgramDetails
