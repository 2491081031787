import {
  CURRENT_LOCATION_KEY,
  MY_CART,
  NAVIGATION_FROM_APP,
} from '@/constants/common'
import CurrentLocation from '@/models/Location'
import { Cart } from '@/models/Product'
import Mixpanel from '@/utils/analytics/mixpanel'

import {
  getCookieValue,
  getLocalStorageKey,
  isDigiAcreUser,
  isValidJSON,
} from '../helper'
import { isMoengageIntialized } from '../moengage'
import { conversionLabels } from './conversionLabels'
import CustomEventTypes from './customEventTypes'
import {
  CartCaptureEvent,
  ProductListTypeEnum,
  PurchaseEvent,
  ViewEvent,
} from './types'

export const event = (eventType: string, params: Gtag.CustomParams) => {
  if (window.gtag != null) {
    window.gtag('event', eventType, {
      ...params,
      page_location: window.location.href,
    })
  }
}

export const logException = (description: string, fatal = false) => {
  if (window.gtag != null) {
    window.gtag('event', 'exception', {
      description,
      fatal,
    })
  }
}

const getListDetails = (type?: ProductListTypeEnum, feedListName = 'feed') => {
  switch (type) {
    case ProductListTypeEnum.SEARCH:
      return { item_list_id: 'product_search', item_list_name: 'Search' }
    case ProductListTypeEnum.PRODUCT:
      return { item_list_id: 'product', item_list_name: 'Product' }
    case ProductListTypeEnum.FEED:
      return { item_list_id: 'feed', item_list_name: feedListName }
    case ProductListTypeEnum.CROP_PROBLEM:
      return { item_list_id: 'crop_problem', item_list_name: 'Crop Problem' }
    case ProductListTypeEnum.PRODUCT_DETAIL:
      return {
        item_list_id: 'product_detail',
        item_list_name: 'Product Detail',
      }
    default:
      return { item_list_id: 'cart', item_list_name: 'Cart' }
  }
}

export const CaptureUpdateCartEvent = ({
  eventType,
  type,
  variantId,
  variantPrice,
  variantDiscount,
  name,
  brandName,
  listName = 'feed',
  quantity = 1,
  index,
  category,
}: CartCaptureEvent) => {
  if (window.gtag != null) {
    const cart = getLocalStorageKey<Cart, null>(MY_CART, null)
    const location = getLocalStorageKey<CurrentLocation, null>(
      CURRENT_LOCATION_KEY,
      null
    )
    const vendorId = cart != null ? cart.vendorId : null
    const price = quantity * variantPrice
    const discount = quantity * variantDiscount
    const totalCost = price - discount
    window.gtag('event', eventType, {
      currency: 'INR',
      value: totalCost,
      items: [
        {
          item_id: variantId,
          item_name: name,
          affiliation: vendorId,
          discount,
          index,
          item_brand: brandName,
          item_category: category?.name,
          ...getListDetails(type, listName),
          item_variant: variantId,
          location_id: location?.placeId,
          price,
          quantity,
        },
      ],
    })
  }
}

export const capturePurchaseEvent = ({
  orderId,
  value,
  currency = 'INR',
  coupon,
  products,
  type = 'purchase',
  shipping,
}: PurchaseEvent) => {
  if (window.gtag != null) {
    window.gtag('event', type, {
      transaction_id: orderId,
      value,
      currency,
      coupon,
      shipping,
      items: products.map((product, index) => ({
        item_id: product.productId,
        item_name: product.itemName,
        currency,
        discount: product.discount,
        item_brand: product.brandId,
        item_variant: product.variantId,
        item_category: product.categoryId,
        index: index + 1,
        quantity: product.quantity,
        price: product.price,
        affiliation: product.vendorName,
        coupon,
      })),
    })
  }
}

export const captureViewEvent = ({
  currency = 'INR',
  type,
  product,
}: ViewEvent) => {
  if (window.gtag != null) {
    const { id, name, product_variants: variants, brand, category } = product
    let listType = {}
    if (type != null) {
      listType = getListDetails(type)
    }
    window.gtag('event', 'view_item', {
      items: variants
        .filter((variant) => variant.inventory_set.length > 0)
        .map((variant, index) => ({
          index,
          currency,
          item_id: id,
          item_name: name,
          discount: variant.inventory_set[0].discount,
          price: variant.inventory_set[0].price,
          item_brand: brand?.name,
          item_category: category?.name,
          item_variant: variant.id,
          ...listType,
        })),
    })
  }
}

export const trackCustomEvent = (
  eventType: CustomEventTypes,
  params?: string,
  productName?: string
) => {
  const utmParams = sessionStorage.getItem('utm_params')
  if (window.gtag != null) {
    let eventParams: Record<string, string> = {
      page_location: window.location.href,
      from_app: getCookieValue(NAVIGATION_FROM_APP) ? '1' : '0',
      is_ondc: window.location.pathname.includes('/ondc') ? '1' : '0',
      is_digiacre: isDigiAcreUser() ? '1' : '0',
    }
    let eventName = `${eventType}`
    if (params) {
      eventParams = { ...eventParams, event_params: params }
    }
    if (utmParams) {
      eventParams = { ...eventParams, ...JSON.parse(utmParams) }
    }
    if (productName) {
      eventName = `${eventType}/${productName}`
    }
    window.gtag('event', eventName, eventParams)
    //track event on Moengage as well so that users can be filtered based on this data
    if (isMoengageIntialized()) {
      Moengage.track_event(eventName, eventParams)
    }
    // Mixpanel track event
    Mixpanel.track(eventName, {
      ...eventParams,
      ...(params && isValidJSON(params) ? JSON.parse(params) : {}),
    })
  }
}

export const trackGAConversionEvent = (
  eventCategory: string,
  params: Gtag.CustomParams,
  conversionLabel: conversionLabels
) => {
  const utmParams = sessionStorage.getItem('utm_params')

  if (window.gtag != null) {
    let eventParams: Record<string, string> = {
      send_to: `${process.env.NEXT_PUBLIC_GA_CONVERSION_KEY}/${conversionLabel}`,
      event_category: eventCategory,
      page_location: window.location.href,
      from_app: getCookieValue(NAVIGATION_FROM_APP) ? '1' : '0',
      ...params,
    }

    if (utmParams) {
      eventParams = { ...eventParams, ...JSON.parse(utmParams) }
    }

    window.gtag('event', 'conversion', eventParams)
  }
}
