export const OTP_LENGTH = 6
export const TIMER_IN_MS = 30000


export const enum CSC_LOGIN {
    GRANT_TYPE = 'authorization_code',
    STATE = 'state',
    CODE = 'code',
    CSC = 'csc',
    PASSWORD = 'password',
    CONTENT_TYPE= 'application/x-www-form-urlencoded'
}
