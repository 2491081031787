import updateUserDetails from '@dehaat/kisan-app-bl/api/updateUserDetails';
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common';
import User from '@dehaat/kisan-app-bl/models/User';
import * as Sentry from '@sentry/nextjs';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { LANG_UPDATE_KEY, LOCALE_COOKIE_NAME, WEB_SOURCE_KEY } from '@/constants/common';
import fetchUserDetails from '@/utils/api/fetchUserDetails';
import { getCookieValue, isCSCUser } from '@/utils/helper';
import { AuthContext } from './AuthProvider';
interface ContextProps {
  user?: User;
  isLoading: boolean;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}
export const UserContext = createContext<ContextProps>({
  isLoading: false,
  setUser: () => {
    /* nop */
  }
});
interface Props {
  children: ReactNode;
}
/**
 * A React provider for providing User related information to its children.
 *
 * @author Yogender Jangir <yogender.jangir@agrevolution.in>
 */
const UserProvider = ({
  children
}: Props) => {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * To be triggered on mounting of the component to fetch user details
   * and to set the state of the component accordingly
   */
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      const createOrSetProfile = async () => {
        // Get the user first, check if the profile is set
        // If it is set, then (update the user state in context) else (create the profile and then update user, see the note below)
        // NOTE: Beware!!! this will create a profile with the language from cookies
        try {
          let user: User | null;
          user = await fetchUserDetails();
          if (user && !user.profile) {
            // Create profile with the language set in cookies
            const lang = getCookieValue(LOCALE_COOKIE_NAME) || DEFAULT_LANG;
            user = await updateUserDetails({
              [LANG_UPDATE_KEY]: lang
            }, isCSCUser() ? Number(process.env.NEXT_PUBLIC_CSC_WEB_SOURCE_KEY) : WEB_SOURCE_KEY);
          }
          if (user) {
            setUser(user);
            Sentry.setUser({
              email: user.email || '',
              username: `${user.username} - (${user.phone_number || ''}`,
              id: user.id
            });
          } else {
            throw 'Could not update user';
          }
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
        }
      };
      createOrSetProfile();
    }
  }, [isAuthenticated]);
  return <UserContext.Provider value={{
    user,
    isLoading,
    setUser
  }} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="UserProvider.tsx">
      {children}
    </UserContext.Provider>;
};
export default UserProvider;