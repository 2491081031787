import { GEO_SETTINGS } from '@/constants/location'
import CurrentLocation from '@/models/Location'

export type LocationType = {
  latitude: number
  longitude: number
  accuracy: number
}

export const getCurrentLocationValues = async (): Promise<
  LocationType | undefined
> => {
  try {
    const {
      coords: { latitude, longitude, accuracy },
    } = await new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, GEO_SETTINGS)
    )
    return {
      latitude,
      longitude,
      accuracy,
    }
  } catch {
    console.error('Error in navigator')
  }
}

export const getLocationDetails = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  let area = ''
  let city = ''
  let postalCode = ''
  addressComponents.forEach(({ types, long_name }) => {
    if (types.indexOf('locality') !== -1) {
      area = long_name
    }
    if (types.indexOf('administrative_area_level_1') !== -1) {
      city = long_name
    }
    if (types.indexOf('postal_code') !== -1) {
      postalCode = long_name
    }
  })
  return { area, city, postalCode }
}

export const getGeoLocationValues = async (
  lat: number,
  lng: number
): Promise<CurrentLocation> => {
  let formattedAddress = ''
  let placeId = ''
  let locationDetails = { area: '', city: '', postalCode: '' }
  const geocoder = new google.maps.Geocoder()
  try {
    const { results } = await geocoder.geocode({ location: { lat, lng } })
    if (Array.isArray(results) && results.length > 0) {
      const firstResult = results[0]
      placeId = firstResult.place_id
      formattedAddress = firstResult.formatted_address
      locationDetails = getLocationDetails(firstResult.address_components)
    }
  } catch (e) {
    console.error('Error in geocoder : ' + e)
  }
  return {
    ...locationDetails,
    formattedAddress,
    placeId,
    latLng: `${lat},${lng}`,
  }
}
