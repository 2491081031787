/**
 * What's it? - redux hook wrapper
 *
 * While it's possible to import the RootState and AppDispatch types into each
 * component, it's better to create typed versions of the useDispatch and useSelector
 * hooks for usage in your application
 *
 * instead of plain `useDispatch` and `useSelector`
 * please use `useAppDispatch` and `useAppSelector` in your application
 */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { AppDispatch, RootState } from '@/store/index'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
