module.exports = {
  locales: ['hi', 'en', 'bn', 'mr', 'or', 'gu'],
  defaultLocale: 'hi',
  localeDetection: false,
  pages: {
    '*': [
      'common',
      'products',
      'cart',
      'address',
      'cart',
      'cartInsurance',
      'insurance',
      'login',
      'idKyc',
      'kyc',
      'nearbyDc',
      'sowingDate',
      'payments',
      'location',
    ],
    '/': [
      'agriExpert',
      'askQuestion',
      'cropProblems',
      'insurance',
      'main',
      'nearbyDc',
      'order',
      'posts',
      'productFeed',
      'location',
      'soilTest',
    ],
    '/404': ['404'],
    '/address/[id]/edit': ['address'],
    '/address/new': ['address'],
    '/addresses': ['address'],
    '/growth/address': ['address'],
    '/all-brands': ['main'],
    '/all-categories': ['main'],
    '/brands': ['main'],
    '/categories': ['main'],
    '/products/categories': ['main'],
    '/app/404': ['404'],
    '/ask-agri-question': ['askQuestion', 'cropProblems', 'login'],
    '/ask-help': ['askQuestion'],
    '/ask-question': [
      'askQuestion',
      'common',
      'cropProblems',
      'login',
      'sowingDate',
    ],
    '/blogs': ['cropProblems', 'main', 'posts'],
    '/blogs/channel/[slug]': ['cropProblems', 'cart'],
    '/blogs/tag/[slug]': ['cropProblems', 'cart'],
    '/cart': [
      'address',
      'cart',
      'cartInsurance',
      'insurance',
      'login',
      'idKyc',
      'kyc',
      'nearbyDc',
      'sowingDate',
      'payments',
    ],
    '/community/post/[slug]': ['askQuestion', 'cropProblem', 'main', 'posts'],
    '/crop-guide': ['cropGuide', 'sowingDate'],
    '/crop/[id]/problem/[problemId]': [
      'askQuestion',
      'cropProblem',
      'nearbyDc',
    ],
    '/crop/[id]/problems': ['cropProblems'],
    '/crop/all': ['crop'],
    '/crops': ['crop'],
    '/crop': ['crop'],
    '/products/crops': ['crop'],
    '/dcs': ['nearbyDc'],
    '/dehaat/post/[slug]': ['askQuestion', 'cropProblem', 'main', 'posts'],
    '/blog/[slug]': ['askQuestion', 'cropProblem', 'main', 'posts'],
    '/farm/[id]': ['agriExpert', 'bioticStress', 'common'],
    '/feedback': ['bioticStress'],
    '/farm-talk': ['cropProblems', 'main', 'posts'],
    '/insurance': ['agriExpert', 'insurance', 'nearbyDc'],
    '/insurance/claims': ['insurance', 'products'],
    '/insurance/claims/[id]': ['bankKyc', 'insurance'],
    '/insurance/kyc': ['kyc'],
    '/insurance/kyc/banks': ['bankKyc', 'kyc'],
    '/insurance/kyc/banks/[id]': ['bankKyc', 'kyc'],
    '/insurance/kyc/banks/new': ['bankKyc', 'kyc'],
    '/insurance/kyc/id': ['common', 'idKyc', 'kyc'],
    '/insurance/perils/[id]': ['insurance'],
    '/insurance/policies': ['insurance', 'products'],
    '/insurance/policies/[id]': ['bankKyc', 'insurance'],
    '/insurance/policies/term-sheets': ['termSheet'],
    '/language': ['language'],
    '/location': ['location'],
    '/login': ['login'],
    '/nearby-dcs': ['nearbyDc'],
    '/news': ['news'],
    '/order/[orderId]': [
      'cart',
      'cartInsurance',
      'insurance',
      'nearbyDc',
      'order',
    ],
    '/orders': ['order'],
    '/prakshep': ['prakshep', 'satellite', 'crop'],
    '/products': ['products'],
    '/products/[id]': ['order', 'products'],
    '/product/[slug]': ['order', 'products'],
    '/products/brands': ['main'],
    '/productsV2': ['products'],
    '/productsV2/[id]': ['order', 'products'],
    '/productV2/[slug]': ['order', 'products'],
    '/productsV2/brands': ['main'],
    '/cart/kyc/id': ['idKyc', 'kyc'],
    '/prescription/[id]': ['nearbyDc', 'posts', 'prescription'],
    '/soil-test-report': ['soilTest'],
    '/soil-test-report/[id]': ['soilTest', 'prescription', 'address'],
    '/growth/validate': ['login'],
    '/growth/cart': ['cart'],
    '/mandi': ['cropGuide', 'mandi', 'bioticStress'],
    '/mandi/search': [
      'cropGuide',
      'mandi',
      'crop',
      'bioticStress',
      'askQuestion',
    ],
    '/mandi/submit-reciept': ['cropGuide', 'mandi', 'crop', 'bioticStress'],
    '/mandi/demand-trade': ['mandi', 'crop', 'address', 'shriProgram', 'main'],
    '/mandi/collection-center': [
      'mandi',
      'crop',
      'main',
      'address',
      'shriProgram',
    ],
    '/shri-program': ['shriProgram'],
    '/shri-program/activation': ['shriProgram', 'address', 'cart', 'login'],
    '/shri-program/apply': [
      'crop',
      'shriProgram',
      'address',
      'cart',
      'login',
      'bioticStress',
    ],
    '/ondc/cart': ['cart'],
    '/ondc': ['main'],
    '/ondc/dcs': ['nearbyDc'],
    '/ondc/cart': ['cart', 'address'],
    '/ondc/location': ['location'],
    '/ondc/order/[ondcOrderId]': ['cart', 'nearbyDc', 'order'],
    '/ondc/products/[id]': ['order'],
    '/delete-account': ['login'],
    '/geotag-campaign': ['satellite', 'common', 'crop', 'main'],
    '/farms': ['satellite', 'crop'],
    '/farmer-services/soil-testing': ['soilTest'],
    '/farmer-services/package-list': ['soilTest'],
    '/farmer-services/cart': ['soilTest'],
    '/farmer-services/booking-form': ['soilTest','crop'],
    '/farmer-services/booking-details/[bookingId]': ['soilTest', 'order'],
    '/farmer-services/booking-list': ['soilTest'],
  },
}
