import User from '@dehaat/kisan-app-bl/models/User'

import { get } from '../axios'
import { get as serverGet } from '../axios/serverConfig'

const fetchUserDetails = () => get<User>('/user/v1/details')

export const fetchServerUserDetails = (accessToken: string) =>
  serverGet<User>('/user/v1/details', {}, accessToken)

export default fetchUserDetails
